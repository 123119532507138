import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  SpaceThemeNames,
  ThemeHeaderTitleOptions,
  Button,
  Flex,
  Portal,
  buildURL,
  PortalPermission,
  SpacePermission,
} from "@thenounproject/lingo-core";
import { isEqual } from "lodash";

import ThemeEditorAccordion from "./ThemeEditorAccordion";
import ThemeEditorOption from "./ThemeEditorOption";
import ThemeImagePicker from "./ThemeImagePicker";
import ThemeTextArea from "./ThemeTextArea";
import ThemeFontPicker from "./ThemeFontPicker";
import ThemeColorPicker from "./ThemeColorPicker";
import ThemeHeaderBackgroundSelector from "./ThemeHeaderBackgroundSelector";
import ThemeSelect from "./ThemeSelect";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";
import {
  SpaceThemeColorOptions,
  SpaceThemeImageOptions,
  SpaceThemeTextOptions,
  SpaceThemeSelectOptions,
  themeSelectOptions,
  unsavedChangesModalTextProps,
} from "@features/theming/types";
import { themeTitleSelectOptions } from "../../../components/portals/types";

import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useUpdatePortalTheme from "@redux/actions/portals/useUpdatePortalTheme";
import useThemeFonts from "@hooks/useThemeFonts";
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { getThemeOverrides } from "@contexts/ThemeDataContext";
import ThemeEditorPanelHeader from "./ThemeEditorPanelHeader";

const Wrapper = styled(Box).attrs({
  borderLeft: "default",
  flex: "240px 1 0",
  background: "white",
  overflow: "auto",
  height: "100%",
})``;

type Props = {
  portal: Portal;
};

const PortalThemeControlPanel = ({ portal }: Props) => {
  const space = useSelectSpace();
  const spaceTheme = space?.theme;
  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();

  const {
    updateEditingTheme: updateTheme,
    themeChanged,
    editingTheme,
    theme,
    endEditing,
    existingTheme,
    newFiles,
    updateFiles,
    filesChanged,
  } = useThemeDataContext();

  const { data: fonts = [] } = useThemeFonts({ spaceId: space?.id }, { refetchOnMount: true });
  const [updatePortalTheme, { isProcessing }] = useUpdatePortalTheme();

  const newTheme = editingTheme ?? theme;
  const { headerTitleStyle } = newTheme;
  const disabled = isProcessing || !themeChanged;

  const titleLogo = headerTitleStyle === ThemeHeaderTitleOptions.logo;
  const titlePortalName = headerTitleStyle === ThemeHeaderTitleOptions.name;

  const portalPerms = portal.access.permissions,
    canManagePortalThemeFont = portalPerms.includes(PortalPermission.manageCustomThemeFont),
    canManagePortalThemeAppearance = portalPerms.includes(
      PortalPermission.manageCustomThemeAppearance
    ),
    spacePerms = space.access.permissions,
    canCustomizeSpaceTheme = spacePerms.includes(SpacePermission.manageCustomTheme);

  useEffect(() => endEditing, [endEditing]);

  const exitConfigureMode = useCallback(() => {
    endEditing();
    window.location.hash = "";
  }, [endEditing]);

  function handleExitEditor() {
    if (themeChanged) {
      return showModal(ModalTypes.CONFIRMATION, {
        ...unsavedChangesModalTextProps,
        onConfirm: exitConfigureMode,
      });
    } else {
      exitConfigureMode();
    }
  }

  const saveTheme = useCallback(async () => {
    if (isProcessing) return null;
    if (!newTheme.headerBackgroundColor && !newTheme.headerBackgroundImage)
      return showNotification({
        message: "Please select a header background color or image.",
        level: "error",
      });

    // Send null for options that are the same as space theme, since they are inherited from space
    // Except for header options since those don't exist on space, if portals exist
    const theme = {
      description: newTheme.description || null,
      headerBackgroundColor: newTheme.headerBackgroundColor,
      headerTitleStyle: newTheme.headerTitleStyle,
      headerTitleColor: newTheme.headerTitleColor,
      headerDescriptionColor: newTheme.headerDescriptionColor,
      ...getThemeOverrides(newTheme, spaceTheme),
    };

    if (filesChanged) {
      Object.keys(newFiles).forEach(key => {
        if (!isEqual(existingTheme[key], newTheme[key])) {
          theme[key] = newFiles[key];
        }
      });
    }

    const { error } = await updatePortalTheme({
      portalId: portal.id,
      updates: theme,
    });
    if (error) {
      showNotification({ message: error.message, level: "error" });
    } else {
      showNotification({ message: "Theme updated successfully.", level: "info" });
      exitConfigureMode();
    }
  }, [
    existingTheme,
    exitConfigureMode,
    filesChanged,
    isProcessing,
    newFiles,
    newTheme,
    portal.id,
    showNotification,
    spaceTheme,
    updatePortalTheme,
  ]);

  /* Render functions */
  function renderPortalHeaderSection() {
    return (
      <ThemeEditorAccordion title="Portal Header" openByDefault>
        <ThemeEditorOption title="Title">
          <ThemeSelect<ThemeHeaderTitleOptions>
            {...{
              newTheme,
              updateTheme,
              themeSelectOption: SpaceThemeSelectOptions.headerTitleStyle,
              options: themeTitleSelectOptions,
            }}
          />
          <Box mt="s">
            {titlePortalName && (
              <ThemeColorPicker
                {...{
                  existingTheme: spaceTheme,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.headerTitle,
                }}
              />
            )}
            {titleLogo && (
              <ThemeImagePicker
                {...{
                  existingTheme,
                  newTheme,
                  updateTheme,
                  updateFiles,
                  themeImageOption: SpaceThemeImageOptions.headerLogoImage,
                  sizeRecommendation: "400px wide PNG",
                }}
              />
            )}
          </Box>
        </ThemeEditorOption>
        <ThemeEditorOption title="Background">
          <ThemeHeaderBackgroundSelector
            {...{
              existingTheme,
              newTheme,
              updateTheme,
              updateFiles,
            }}
          />
        </ThemeEditorOption>
        <ThemeEditorOption title="Description">
          <ThemeTextArea
            {...{
              newTheme,
              updateTheme,
              themeTextOption: SpaceThemeTextOptions.description,
            }}
          />
          <Box mt="s">
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.headerDescription,
              }}
            />
          </Box>
        </ThemeEditorOption>
      </ThemeEditorAccordion>
    );
  }

  function renderNoteHighlightingSection() {
    return (
      <ThemeEditorAccordion title="Note Highlighting" openByDefault>
        <ThemeEditorOption title="Colors">
          <Flex flexDirection="column" gap="4px">
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.noteInfo,
              }}
            />
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.noteWarning,
              }}
            />
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.noteSuccess,
              }}
            />
          </Flex>
        </ThemeEditorOption>
      </ThemeEditorAccordion>
    );
  }

  function renderPortalStylesText() {
    return (
      <Box>
        <Text font="ui.small" color="grayDarkest">
          Portal styles are inherited from the space-wide theme. Overrides will apply only to the
          portal page.
        </Text>
        {canCustomizeSpaceTheme && (
          <Button
            text="Adjust space theme"
            size="small"
            buttonStyle="tertiary"
            link={buildURL("/settings/editor", { space })}
          />
        )}
      </Box>
    );
  }

  /* Component render */
  return (
    <Wrapper>
      <ThemeEditorPanelHeader
        onSave={saveTheme}
        onCancel={handleExitEditor}
        disabled={disabled}
        isProcessing={isProcessing}
      />
      <Box>
        <ThemeEditorAccordion title="Inherited Styles" openByDefault>
          {renderPortalStylesText()}
          <ThemeEditorOption
            title="Theme"
            upgradeRequired={!canManagePortalThemeAppearance}
            upgradeMessage="Themes are available for Enterprise customers.">
            <ThemeSelect<SpaceThemeNames>
              {...{
                newTheme,
                updateTheme,
                themeSelectOption: SpaceThemeSelectOptions.themeName,
                options: themeSelectOptions,
              }}
            />
          </ThemeEditorOption>
          <ThemeEditorOption
            title="Font"
            customizationMessage="to use a custom font"
            upgradeRequired={!canManagePortalThemeFont}
            upgradeMessage="Font customization is available for Enterprise customers.">
            <ThemeFontPicker {...{ fonts, newTheme, existingTheme, updateTheme }} />
          </ThemeEditorOption>
          <ThemeEditorOption title="Colors">
            <ThemeColorPicker
              {...{
                existingTheme: spaceTheme,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.accent,
              }}
            />
          </ThemeEditorOption>
        </ThemeEditorAccordion>
        {renderPortalHeaderSection()}
        {renderNoteHighlightingSection()}
      </Box>
    </Wrapper>
  );
};

export default PortalThemeControlPanel;
